import React, { createContext, useState } from "react";

const UIContext = createContext();

export const UIProvider = ({ children }) => {

    const [ isExpandedMenu, setIsExpandedMenu ] = useState(false);
    const [ isViewMained, setIsViewMained ] = useState('home');
    const [ isViewMessageAlert, setIsViewMessageAlert ] = useState({
        type: '',
        text: ''
    });

    const [ isViewModal, setIsViewModal ] = useState({
        title: '',
        used: ''
    });

    const handleExpandedMenu = () => setIsExpandedMenu(!isExpandedMenu);
    const handleViewMained = (mained) => {
        const titleMap = {
            home: 'Inicio',
            students: 'Estudiantes',
            pensions: 'Pensiones',
            teachers: 'Maestros',
            classroom: 'Aulas',
            courses: 'Cursos',
            inventary: 'Inventario',
            settings: 'Configuración',
            ratings: 'Calificaciones',
            schedule: 'Horario',
            profile: 'Perfil',

        };
        setIsViewMained(mained);
        let titleWindow = titleMap[mained] || '';
        document.title = `${titleWindow} | Sigma Platform`;
    }

    const handleViewMessageAlert = (typeMessage, textMessage) => {
        setIsViewMessageAlert({
            type: typeMessage,
            text: textMessage
        })
    }

    const handleViewModal = (titleModal, usedModal) => {
        setIsViewModal({
            title: titleModal,
            used: usedModal
        })
    }

    const contextValue = {
        isExpandedMenu, handleExpandedMenu,
        isViewMained, handleViewMained,
        isViewMessageAlert, handleViewMessageAlert,
        isViewModal, handleViewModal
    }

    return (
        <UIContext.Provider value={contextValue}>{children}</UIContext.Provider>
    )

}

export default UIContext;