import React, { useContext, useEffect, useState } from 'react'
import { IconEye, IconEyeOff } from '@tabler/icons-react';
import { Link } from 'react-router-dom';

import RecoverAccountContext from '../context/RecoverAccountContext';
import UIContext from '../context/UIContext';

import Snackbar from '../components/Snackbar/Snackbar';

import './styles/recover.css'

function RecoverScreen() {

    const { isViewMessageAlert } = useContext(UIContext);
    const { isToStep,txtState,isLoadingRecover,handleCapturedData,handleCapturedIdUser,handleRecoverSessionUser, handleChangeRecoverUser } = useContext(RecoverAccountContext);

    const [ isViewPasswords, setIsViewPasswords ] = useState(false);

    useEffect(() => {
        document.title = 'Recuperar Contraseña | Sigma Platform';
    }, []);

    return (


        <>

            <div className='--r3c0v3r'>

                <div className='--card'>

                    <div className='--card-head'>
                        <h2>{txtState.title}</h2>
                        {isToStep !== 4 && (
                            <div dangerouslySetInnerHTML={{ __html: txtState.texto }} />
                        )}
                    </div>

                    <div className='--card-body'>
                        
                        {isToStep === 1 && (
                            <div className='--card-content'>
                                <label className={`--label`} htmlFor='r-uuid'>Ingresar ID para recuperar tu cuenta.</label>
                                <input type='text' className={`--entry`} name='ruuid' id='r-uuid' placeholder='Ingresar ID' aria-label='Ingresar ID' onChange={(e) => handleCapturedData(e)}/>
                            </div>
                        )}

                        {isToStep === 2 && (
                            <div className='--card-content'>
                                <label className={`--label`} htmlFor='c-uurv'>Ingresar código de 6 dígitos para recuperar tu cuenta.</label>
                                <input type='text' className={`--entry`} name='cuurv' id='c-uurv' placeholder='Ingresar código de 6 dígitos para recuperar tu cuenta.' aria-label='Ingresar código de 6 dígitos para recuperar tu cuenta.' onChange={(e) => handleCapturedData(e)} />
                            </div>
                        )}

                        {isToStep === 3 && (
                            <div className='--card-content'>
                                <label className={`--label`} htmlFor='c-uupw'>Ingresar nueva contraseña</label>
                                <div className={`--form-control`}>
                                    <input type={`${isViewPasswords ? 'text' : 'password'}`} className={`--entry`} name='cuupw' id='c-uupw' placeholder='Ingresar nueva contraseña' aria-label='Ingresar nueva contraseña' onChange={(e) => handleCapturedData(e)}/>
                                    <span className={`--span --span-ico`} onClick={() => setIsViewPasswords(!isViewPasswords)}>{isViewPasswords ? <IconEyeOff/> : <IconEye/>}</span>
                                </div>
                            </div>
                        )}

                        {isToStep === 4 && (
                            <div className='--card-central'>
                                <div>
                                    <p>{txtState.texto}</p>
                                    <Link className={`--a-btn --a-btn-info`} to={'/'}>Volver</Link>
                                </div>
                            </div>
                        )}

                    </div>

                    <div className='--card-foot'>
                        {isToStep === 1 && (
                            <button className={`--btn --btn-info`} onClick={handleCapturedIdUser}>{isLoadingRecover ? 'Cargando...' : 'Siguiente'}</button>
                        )}
                        {isToStep === 2 && (
                            <>
                                <button className={`--btn`}>Anterior</button>
                                <button className={`--btn --btn-info`} onClick={handleRecoverSessionUser}>{isLoadingRecover ? 'Cargando...' : 'Siguiente'}</button>
                            </>
                        )}
                        {isToStep === 3 && (
                            <>
                                <button className={`--btn`}>Anterior</button>
                                <button className={`--btn --btn-info`} onClick={handleChangeRecoverUser}>Actualizar</button>
                            </>
                        )}
                    </div>
                    
                    {isToStep !== 4 && (
                        <div className={`--card-text`}>
                            <span>¿Tienes una cuenta?. <b><Link to={'/'}>Ingresar</Link></b></span>
                        </div>
                    )}

                </div>

            </div>

            {isViewMessageAlert.text !== '' && ( <Snackbar data={isViewMessageAlert} /> )}
        
        </>

    )

}

export default RecoverScreen