import React from 'react'

import './styles/home.css'
import Birthday from '../components/Birthday/Birthday'
import ShortCut from '../components/Shortcuts/ShortCut'

function HomeView() {

    return (

        <div className={`--box-view`}>
        
            <div className={`--col --col-A`}>

                <ShortCut type={'statistics'} />

                <ShortCut type={'functions'} />

            </div>

            <div className={`--col --col-B`}>

                <Birthday/>

            </div>

        </div>

    )

}

export default HomeView