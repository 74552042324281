import React, { useContext } from 'react'
import UIContext from '../context/UIContext'

import './styles/layout.css'

function LayoutView({ title, content }) {

    const { handleViewModal } = useContext(UIContext);

    const handleUseFunction = () => handleViewModal(title, content);

    return (

        <div className={`--layout-header`}>

            <div className='--col'></div>

            <div className='--col --col-B'>
                <button className={`--btn --btn-danger`}>Eliminar</button>
                <button className={`--btn --btn-info`} onClick={handleUseFunction}>Crear</button>
            </div>

        </div>

    )

}

export default LayoutView