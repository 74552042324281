import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { IconApps, IconBook, IconCalendar, IconHome, IconLayoutSidebarLeftExpand, IconLayoutSidebarRightExpand, IconLogout, IconSchool, IconSettings, IconUserCircle, IconUserStar, IconUsersGroup, IconVocabulary, IconWallet } from '@tabler/icons-react'
import UIContext from '../context/UIContext'
import AuthenticatedContex from '../context/AuthenticatedContex'

import logo from '../static/img/logo-square.png'

import './styles/nav.css'

function Navigation({ rolUser }) {

    const { isExpandedMenu, handleExpandedMenu, isViewMained, handleViewMained } = useContext(UIContext);
    const { handleLogoutSessionUser } = useContext(AuthenticatedContex);

    const navigationItemsUser1  = [
        {
            text: 'Inicio',
            icon: <IconHome/>,
            stated: 'home'
        },
        {
            text: 'Estudiantes',
            icon: <IconUserStar/>,
            stated: 'students'
        },
        {
            text: 'Pensiones',
            icon: <IconWallet/>,
            stated: 'pensions'
        },
        {
            text: 'Profesores',
            icon: <IconSchool/>,
            stated: 'teachers'
        },
        {
            text: 'Aulas',
            icon: <IconUsersGroup/>,
            stated: 'classroom'
        },
        {
            text: 'Cursos',
            icon: <IconBook/>,
            stated: 'courses'
        },
        {
            text: 'Inventario',
            icon: <IconApps/>,
            stated: 'inventary'
        },
        {
            text: 'Configuración',
            icon: <IconSettings/>,
            stated: 'settings'
        }
    ];
    const navigationItemsUser2  = [
        {
            text: 'Inicio',
            icon: <IconHome/>,
            stated: 'home'
        },
        {
            text: 'Estudiantes',
            icon: <IconUserStar/>,
            stated: 'students'
        },
        {
            text: 'Calificaciones',
            icon: <IconVocabulary/>,
            stated: 'ratings'
        },
        {
            text: 'Configuración',
            icon: <IconSettings/>,
            stated: 'settings'
        }
    ];
    const navigationItemsUser3 = [
        {
            text: 'Inicio',
            icon: <IconHome/>,
            stated: 'home'
        },
        {
            text: 'Cursos',
            icon: <IconBook/>,
            stated: 'courses'
        },
        {
            text: 'Calificaciones',
            icon: <IconVocabulary/>,
            stated: 'ratings'
        },
        {
            text: 'Horario',
            icon: <IconCalendar/>,
            stated: 'schedule'
        },
        {
            text: 'Pensiones',
            icon: <IconWallet/>,
            stated: 'pensions'
        },
        {
            text: 'Perfil',
            icon: <IconUserCircle/>,
            stated: 'profile'
        }
    ]

    const navigationItems = rolUser === '1' ? navigationItemsUser1 :
                            rolUser === '2' ? navigationItemsUser2 :
                            rolUser === '3' ? navigationItemsUser3 : [];

    return (

        <nav className={`--nav ${rolUser}`}>

            <div className={`--nav-head`}>

                <div className={`--nav-head-banner`}>
                    <div className={`--banner-logo`}>
                        <img src={logo} aria-label='Logo de la institución educativa SIGMA | PREPOLI | ACADEMIAS | JAUJA - PERÚ' alt='Logo de la institución educativa SIGMA | PREPOLI | ACADEMIAS | JAUJA - PERÚ' />
                    </div>
                    <div className={`--banner-text`}><span>Sigma Platform</span></div>
                </div>

            </div>

            <div className={`--nav-body`}>
                <ul className={`--ul-nav`}>
                    {navigationItems.map((item, index) => (
                        <li key={index} className={`--li-nav ${item.stated === isViewMained ? '--li-nav--active' : ''}`} onClick={() => handleViewMained(item.stated)}>
                            <Link className={`--a-nav`}>
                                <span className={`--ico-span`}>{item.icon}</span>
                                <span>{item.text}</span>
                            </Link>
                        </li>
                    ))}
                </ul>
            </div>
            
            <div className={`--nav-foot`}>

                <div className={`--nav-foot-content`}>
                    <button className={`--btn`} onClick={handleExpandedMenu}>
                        {isExpandedMenu ? (
                            <IconLayoutSidebarRightExpand/>
                        ) : (
                            <IconLayoutSidebarLeftExpand/>
                        )}
                    </button>
                    <button className={`--btn`} onClick={handleLogoutSessionUser}><IconLogout/></button>
                </div>

            </div>

        </nav>

    )

}

export default Navigation