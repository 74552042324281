import React, { useContext } from 'react'
import CollegeContext from '../../../context/CollegeContext'

import './styles.css'

function Collaborators() {

    const { handleChageEntryDataCollaborators } = useContext(CollegeContext);

    return (

        <>
        
            <div className='--title'>
                <h4>Datos del Colaborador</h4>
            </div>

            <div className={`--separator`}></div>

            <div className={`--form-group`}>
                <div className={`--form-control`}>
                    <label className={`--label`} htmlFor='role'>Seleccionar su rol</label>
                    <select className={`--select`} name='role' id='role' defaultValue='' onChange={(e) => handleChageEntryDataCollaborators(e)}>
                        <option value='' hidden selected>Seleccionar su rol</option>
                        <option value={'1'}>Administración</option>
                        <option value={'2'}>Maestro</option>
                    </select>
                </div>
            </div>
            <div className={`--form-group --form-flex`}>
                <div className={`--form-control`}>
                    <label className={`--label`} htmlFor='lastname'>Primer Apellido</label>
                    <input type='text' className={`--entry`} name='lastname' id='lastname' placeholder='Ingresar Primer Apellido' aria-label='Ingresar Primer Apellido' onChange={(e) => handleChageEntryDataCollaborators(e)} />
                </div>
                <div className={`--form-control`}>
                    <label className={`--label`} htmlFor='secondname'>Segundo Apellido</label>
                    <input type='text' className={`--entry`} name='secondname' id='secondname' placeholder='Ingresar Segundo Apellido' aria-label='Ingresar Segundo Apellido' onChange={(e) => handleChageEntryDataCollaborators(e)} />
                </div>
            </div>

            <div className={`--form-group`}>
                <div className={`--form-control`}>
                    <label className={`--label`} htmlFor='fullname'>Nombres Completos</label>
                    <input type='text' className={`--entry`} name='fullname' id='fullname' placeholder='Nombres Completos' aria-label='Nombres Completos' onChange={(e) => handleChageEntryDataCollaborators(e)} />
                </div>
            </div>

            <div className={`--form-group`}>
                <div className={`--form-control`}>
                    <label className={`--label`} htmlFor='position'>Posición</label>
                    <input type='text' className={`--entry`} name='position' id='position' placeholder='Posición' aria-label='Posición' onChange={(e) => handleChageEntryDataCollaborators(e)} />
                </div>
            </div>
            
            <div className={`--form-group --form-flex`}>
                <div className={`--form-control`}>
                    <label className={`--label`} htmlFor='dni'>DNI</label>
                    <input type='text' className={`--entry`} name='dni' id='dni' placeholder='DNI' aria-label='DNI' onChange={(e) => handleChageEntryDataCollaborators(e)} />
                </div>
                <div className={`--form-control`}>
                    <label className={`--label`} htmlFor='civilstatus'>Estado Civil</label>
                    <select className={`--select`} name='civilstatus' id='civilstatus' defaultValue='' onChange={(e) => handleChageEntryDataCollaborators(e)}>
                        <option value='' hidden selected>Seleccionar Estado Civil</option>
                        <option value={'S'}>Soltero</option>
                        <option value={'C'}>Conviviente</option>
                        <option value={'C'}>Casado</option>
                        <option value={'D'}>Divorsiado</option>
                        <option value={'V'}>Viudo</option>
                    </select>
                </div>
            </div>

            <div className={`--form-group --form-flex`}>
                <div className={`--form-control`}>
                    <label className={`--label`} htmlFor='birthday'>Cumpleaños</label>
                    <input type='date' className={`--entry`} name='birthday' id='birthday' placeholder='Cumpleaños' aria-label='Cumpleaños' onChange={(e) => handleChageEntryDataCollaborators(e)} />
                </div>
                <div className={`--form-control`}>
                    <label className={`--label`} htmlFor='phone'>Número de contacto</label>
                    <input type='text' className={`--entry`} name='phone' id='phone' placeholder='Número de contacto' aria-label='Número de contacto' onChange={(e) => handleChageEntryDataCollaborators(e)} />
                </div>
            </div>

            <div className={`--form-group`}>
                <div className={`--form-control`}>
                    <label className={`--label`} htmlFor='email'>Email</label>
                    <input type='email' className={`--entry`} name='email' id='email' placeholder='Email' aria-label='Email' onChange={(e) => handleChageEntryDataCollaborators(e)} />
                </div>
            </div>
            
            <div className={`--form-group`}>
                <div className={`--form-control`}>
                    <label className={`--label`} htmlFor='disease'>Enfermedades</label>
                    <input type='text' className={`--entry`} name='disease' id='disease' placeholder='Enfermedades' aria-label='Enfermedades' onChange={(e) => handleChageEntryDataCollaborators(e)} />
                </div>
            </div>

            <div className={`--form-group`}>
                <div className={`--form-control`}>
                    <label className={`--label`} htmlFor='direction'>Dirección</label>
                    <input type='text' className={`--entry`} name='direction' id='direction' placeholder='Dirección' aria-label='Dirección' onChange={(e) => handleChageEntryDataCollaborators(e)} />
                </div>
            </div>

        </>

    )

}

export default Collaborators