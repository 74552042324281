import React, { useContext } from 'react'
import { IconX } from '@tabler/icons-react'

import UIContext from '../../context/UIContext'
import Students from './used/Students';
import CollegeContext from '../../context/CollegeContext';
import Collaborators from './used/Collaborators';

import './styles.css'

function Modal({ data }) {

    const { handleViewModal } = useContext(UIContext);
    const { isLoading, handleCreateRool, handleEnroll } = useContext(CollegeContext);

    return (

        <div className={`--overlay`}>

            <div className={`--modal`}>

                <div className={`--modal-head`}>
                    <h2>{data.title}</h2>
                    <button className={`--btn`} onClick={() => handleViewModal('', '')}><IconX/></button>
                </div>

                <div className={`--modal-body`}>

                    <form className={`--form`} method='POST' encType='multipart/form-data'>
                        
                        {data.used === 'addStudent' && ( <Students/> )}
                        {data.used === 'addCollaborators' && ( <Collaborators/> )}

                    </form>

                </div>

                {isLoading === false && (
                    <div className={`--modal-foot`}>
                        <button className={`--btn --btn-secondary`} onClick={() => handleViewModal('', '')}>Cancelar</button>

                        {data.used === 'addStudent' && (
                            <button className={`--btn --btn-info`} onClick={handleEnroll}>Crear</button>
                        )}
                        {data.used === 'addCollaborators' && (
                            <button className={`--btn --btn-info`} onClick={handleCreateRool}>Crear</button>
                        )}

                    </div>
                )}

            </div>

        </div>

    )

}

export default Modal