import React, { useContext, useEffect } from 'react'
import UIContext from '../../context/UIContext';
import './styles/style.css'

function Snackbar({ data }) {

    const { handleViewMessageAlert } = useContext(UIContext);

    useEffect(() => {

        const timer = setTimeout(() => {
            handleViewMessageAlert('', '');
        }, 3000);

        return () => clearTimeout(timer);

    });

    return (

        <div className={`--snackbar --snackbar-${data.type}`}>
            <p>{data.text}</p>
        </div>

    )

}

export default Snackbar