import React, { useContext } from 'react'
import API from '../api/api'
import UIContext from '../context/UIContext'

import './styles/main.css'
import AuthenticatedContex from '../context/AuthenticatedContex';
import TeachersView from '../view/TeachersView';
import HomeView from '../view/HomeView';
import StudentsView from '../view/StudentsView';

function Main() {

    const { isViewMained } = useContext(UIContext);
    const { isUserSessions } = useContext(AuthenticatedContex);

    const titleMap = {
        home: 'Bienvenido',
        students: 'Estudiantes',
        pensions: 'Pensiones',
        teachers: 'Maestros',
        classroom: 'Aulas',
        courses: 'Cursos',
        inventary: 'Inventario',
        settings: 'Configuración',
        ratings: 'Calificaciones',
        schedule: 'Horario',
        profile: 'Perfil',
    };
    
    let title = titleMap[isViewMained] || '';

    return (

        <main className={`--main`}>

            <div className={`--box-main`}>

                <div className={`--box-main-head`}>
                    <div>
                        <h1>{title === 'Bienvenido' ? `Bienvenido, ${isUserSessions.fullname_collaborators || isUserSessions.fullname_student}` : title}</h1>
                    </div>
                    <div>
                        <div className={`--banner-profile`}>
                            <div className={`--user-medium`}>
                                <img src={`${API.avatar}&name=${isUserSessions.fullname_collaborators || isUserSessions.fullname_student}`} alt={`Foto de usuario de ${isUserSessions.fullname_collaborators || isUserSessions.fullname_student}`} />
                            </div>
                            <div className={`--name-user`}><span>{isUserSessions.fullname_collaborators || isUserSessions.fullname_student}</span></div>
                        </div>
                    </div>
                </div>

                <div className={`--box-main-body`}>

                    {isViewMained === 'home' && ( <HomeView/> )}
                    
                    {isViewMained === 'students' && ( <StudentsView/> )}

                    {isViewMained === 'teachers' && ( <TeachersView/> )}

                </div>

            </div>

        </main>

    )

}

export default Main