import React, { useContext, useEffect } from 'react'
import LayoutView from './LayoutView'
import ShortCut from '../components/Shortcuts/ShortCut'
import StudentsContext from '../context/StudentsContext'
import Table from '../components/Table/Table';

function StudentsView() {

    const { listStudents, handleCapturedStudent } = useContext(StudentsContext);

    useEffect(() => {
        if (listStudents.length === 0) {
            handleCapturedStudent();
        }
    }, [listStudents, handleCapturedStudent]);

    return (

        <>
        
            <LayoutView title={'Matricular'} content={'addStudent'}/>
            
            <ShortCut type={'studentsAnalytics'} />

            <div className={`--box-content`}>

                <div className={`--col --col-A`}>
                    <Table type={'student'} />
                </div>

                <div className={`--col --col-B`}></div>

            </div>
        
        </>

    )

}

export default StudentsView