import React, { createContext, useState } from "react";
import API from "../api/api";

const StudentsContext = createContext();

export const StudentsProvider = ({ children }) => {

    const [ listStudents, setListStudents ] = useState([]);

    const handleCapturedStudent = async () => {

        try {
            
            const res = await fetch(`${API.api}/college/students/`)
            const data = await res.json();
            if (data.ok) {
                setListStudents(data.content);
            } else {
                console.log(data.message);
            }

        } catch (error) {
            console.log(error.message);
        }

    }

    const contextValue = {
        listStudents,
        handleCapturedStudent
    }

    return (
        <StudentsContext.Provider value={contextValue}>{children}</StudentsContext.Provider>
    )

}

export default StudentsContext;