import React from 'react'
import LayoutView from './LayoutView'

import './styles/teachers.css'
import Table from '../components/Table/Table'

function TeachersView() {

    return (

        <>
        
            <LayoutView/>

            <div className={`--box-content`}>

                <div className={`--column-table`}>
                    <Table type={'teacher'} />
                </div>

                <div className={`--column-more`}></div>

            </div>
        
        </>

    )

}

export default TeachersView