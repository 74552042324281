import React from 'react'

function ErrorScreen() {

    return (

        <div>ErrorScreen</div>

    )
    
}

export default ErrorScreen