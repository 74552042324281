import React, { useContext } from 'react'

import './styles/table.css'
import TeachersContext from '../../context/TeachersContext'
import StudentsContext from '../../context/StudentsContext';

function Table({ type }) {

    const { isteachersList } = useContext(TeachersContext);
    const { listStudents } = useContext(StudentsContext);

    return (

        <>
            {type === 'student' && (

                <table className={`--table`}>

                    <thead className={`--table-head`}>
                        <tr className={`--table-head-row`}>
                            <td className={`--table-head-row-td --table-head-row-td-small`}>
                                <input type='checkbox' />
                            </td>
                            <td className={`--table-head-row-td`}>
                                <span>Nombres Completos</span>
                            </td>
                            <td className={`--table-head-row-td`}>
                                <span>Nivel</span>
                            </td>
                            <td className={`--table-head-row-td`}>
                                <span>Aula</span>
                            </td>
                            <td className={`--table-head-row-td`}>
                                <span>Contacto</span>
                            </td>
                        </tr>
                    </thead>

                    <tbody className={`--table-body`}>

                        {listStudents.map((student) => (
                            <tr key={student.student.id_student} className={`--table-body-row`}>
                                <td className={`--table-body-row-td --table-body-row-td-small`}>
                                    <input type='checkbox' />
                                </td>
                                <td className={`--table-body-row-td`}>
                                    <span>{student.student.fullname_student}</span>
                                </td>
                                <td className={`--table-body-row-td`}>
                                    <span>{student.level.name_level}</span>
                                </td>
                                <td className={`--table-body-row-td`}>
                                    <span>{student.classroom.name_classroom}</span>
                                </td>
                                <td className={`--table-body-row-td`}>
                                    <span>{student.student.phone_student}</span>
                                </td>
                            </tr>
                        ))}

                    </tbody>

                </table>

            )}
            {type === 'teacher' && (
                <table className={`--table`}>

                    <thead className={`--table-head`}>
                        <tr className={`--table-head-row`}>
                            <td className={`--table-head-row-td --table-head-row-td-small`}>
                                <input type='checkbox' />
                            </td>
                            <td className={`--table-head-row-td`}>
                                <span>Nombres Completos</span>
                            </td>
                            <td className={`--table-head-row-td`}>
                                <span>Contacto</span>
                            </td>
                            <td className={`--table-head-row-td`}>
                                <span>Correo</span>
                            </td>
                            <td className={`--table-head-row-td`}>
                                <span>Área</span>
                            </td>
                        </tr>
                    </thead>

                    <tbody className={`--table-body`}>
                        {isteachersList.map((teacher) => (
                            <tr key={teacher.id_collaborators} className={`--table-body-row`}>
                                <td className={`--table-body-row-td --table-body-row-td-small`}>
                                    <input type='checkbox' />
                                </td>
                                <td className={`--table-body-row-td`}>
                                    <span>{teacher.fullname_collaborators} {teacher.firtsname_collaborators} {teacher.secondname_collaborators}</span>
                                </td>
                                <td className={`--table-body-row-td`}>
                                    <span>{teacher.phone_collaborators}</span>
                                </td>
                                <td className={`--table-body-row-td`}>
                                    <span>{teacher.mailprofessional_collaborators}</span>
                                </td>
                                <td className={`--table-body-row-td`}>
                                    <span>{teacher.position_collaborators}</span>
                                </td>
                            </tr>
                        ))}
                    </tbody>

                </table>
            )}
        </>

    )

}

export default Table