import React from 'react'

function Birthday() {

    return (

        <div className={``}>
            <div className={``}>
                <h4>Cumpleaños</h4>
            </div>
        </div>

    )

}

export default Birthday