import React, { useContext } from 'react'
import UIContext from '../context/UIContext'
import AuthenticatedContex from '../context/AuthenticatedContex';

import Navigation from '../layout/Navigation'
import Main from '../layout/Main';

import Snackbar from '../components/Snackbar/Snackbar'

import './styles/home.css'
import Modal from '../components/Modal/Modal';

function HomeScreen() {

    const { isRoleUser } = useContext(AuthenticatedContex);
    const { isExpandedMenu, isViewMessageAlert, isViewModal } = useContext(UIContext);

    return (

        <>

            <div className={`--app ${isExpandedMenu ? '--app-extended' : ''}`}>

                <>

                    <Navigation rolUser={isRoleUser}/>
                    <Main/>
                
                </>
                
                {isViewMessageAlert.text !== '' && ( <Snackbar data={isViewMessageAlert}/> )}
                {isViewModal.used !== '' && ( <Modal data={isViewModal} /> )}
                
            </div>
        
        </>

    )
    
}

export default HomeScreen