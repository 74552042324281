import React, { createContext, useState, useEffect, useContext } from "react";
import Cookies from "js-cookie";
import API from "../api/api";
import { jwtDecode } from "jwt-decode";
import UIContext from "./UIContext";

const AuthenticatedContex = createContext();

export const AuthenticatedProvider = ({ children }) => {

    const { handleViewMessageAlert, handleViewMained } = useContext(UIContext);

    const auth = Cookies.get('c_user');
    const [ isAuthenticated, setIsAuthenticated ] = useState(auth && auth.trim() !== '');
    const [ isRoleUser, setIsRoleUser ] = useState();

    const [ isUserSessions, setUserSessions ] = useState([]);

    const [ isLoadingAuth, setLoadingAuth ] = useState(false);

    const handleCapturedSessionUser = async (userToken) => {

        const decodedToken = jwtDecode(userToken);
        setIsRoleUser(decodedToken.role_id);
        
        try {

            const cachedUser = Cookies.get('c_client');
            
            if (cachedUser) {
                
                const userData = jwtDecode(cachedUser);
                setUserSessions(userData[0])

            } else {

                const res = await fetch(`${API.api}/usuarios/clients/?code=${userToken}`);
                const data = await res.json();

                if (data.ok) {

                    const userData = jwtDecode(data.token);
                    Cookies.set('c_client', data.token, { expires: 365, secure: true })
                    setUserSessions(userData[0]);

                } else {

                    console.log(data.message);

                }

            }

        } catch (error) {
            console.log(error.message);
        }

    }

    const handleLoginSessionUser = async (e, uuid, uupw) => {
        
        e.preventDefault();

        if (uuid === '' || uupw === '') {
            handleViewMessageAlert('error', 'Por favor completa los espacios');
            return;
        }
        
        setLoadingAuth(true);
        
        const formData = new FormData();
        formData.append('uuid', uuid)
        formData.append('uupw', uupw)
        

        try {
            
            await fetch(`${API.api}/usuarios/login.php`, {
                method: 'POST',
                body: formData
            })
            .then((response) => response.json())
            .then((data) => {
                if (data.ok) {
                    setIsAuthenticated(true);
                    handleCapturedSessionUser(data.token)
                    handleViewMessageAlert('success', data.message);
                    Cookies.set('c_user', data.token, { expires: 365 });
                } else {
                    handleViewMessageAlert('warning', data.message);
                }
            })
            .catch((error) => {
                handleViewMessageAlert('error', error.message);
            })
            .finally(() => {
                setLoadingAuth(false);
            })

        } catch (error) {
            handleViewMessageAlert('error', error.message)
        }

    }

    const handleLogoutSessionUser = async () => {

        const cookieSession = Cookies.get('c_user');

        if (cookieSession === '') {
            handleViewMessageAlert('warning', 'Hubo un problema al cerrar sesión');
            return;
        }

        const formData = new FormData();
        formData.append('c_user', cookieSession);

        try {
            
            await fetch(`${API.api}/usuarios/logout.php`, {
                method: 'POST',
                body: formData
            })
            .then((response) => response.json())
            .then((data) => {
                if (data.ok) {
                    handleViewMessageAlert('success', data.message)
                    setIsAuthenticated(false);
                    setUserSessions([]);
                    handleViewMained('home');
                    Cookies.remove('c_user');
                    Cookies.remove('c_client');
                } else {
                    handleViewMessageAlert('warning', data.message)
                }
            })
            .catch((error) => {
                handleViewMessageAlert('error', error.message)
            })

        } catch (error) {
            handleViewMessageAlert('error', error.message)
        }

    }

    useEffect(() => {
        const fetchUserData = async () => {
            if (Cookies.get('c_user') && isUserSessions.length === 0) {
                await handleCapturedSessionUser(Cookies.get('c_user'));
            }
        };

        fetchUserData();
    }, [isUserSessions]);

    const contextValue = {
        isUserSessions,
        isRoleUser,
        isLoadingAuth,
        isAuthenticated, setIsAuthenticated,
        handleLoginSessionUser,
        handleLogoutSessionUser
    }

    return (
        <AuthenticatedContex.Provider value={contextValue}>{children}</AuthenticatedContex.Provider>
    )

}

export default AuthenticatedContex;