import React, { createContext, useContext, useEffect, useState } from "react";
import API from "../api/api";
import UIContext from "./UIContext";

const CollegeContext = createContext();

export const CollegeProvider = ({ children }) => {

    const { handleViewMessageAlert, handleViewModal } = useContext(UIContext);

    const [ levels, setLevels ] = useState([]);
    const [ classroom, setClassroom ] = useState([]);

    const [ dataStudents, setDataStudents ] = useState({
        lastname: '',
        secondname: '',
        fullname: '',
        dni: '',
        sex: '',
        birthday: '',
        phone: '',
        level: '',
        aula: '',
        direction: '',
        disease: '',

        lastnameAttorney: '',
        secondanameAttorney: '',
        fullnameAttorney: '',
        dniAttorney: '',
        phoneAttorney: '',
        parentAttorney: '',

        subamount: '',
        totalamount: '',

    });

    const [ voucherData, setVoucherData ] = useState([]);

    const [ dataCollaborators, setDataCollaborators ] = useState({
        role: '',
        fullname: '',
        lastname: '',
        secondname: '',
        position: '',
        dni: '',
        civilstatus: '',
        birthday: '',
        phone: '',
        email: '',
        disease: '',
        direction: '',
    });

    const [ isLoading, setIsLoading ] = useState(false);

    const handleChageEntryData = (e) => {
        setDataStudents(prevData => ({
            ...prevData,
            [e.target.name]: e.target.value
        }));
    }
    const handleChageEntryDataCollaborators = (e) => {
        setDataCollaborators(prevData => ({
            ...prevData,
            [e.target.name]: e.target.value
        }));
    }

    const handleEnroll = async () => {

        setIsLoading(true);

        const formData = new FormData();

        formData.append('name', dataStudents.fullname);
        formData.append('firtslastname', dataStudents.lastname);
        formData.append('secondlastname', dataStudents.secondname);
        formData.append('dni', dataStudents.dni);
        formData.append('sexo', dataStudents.sex);
        formData.append('birthday', dataStudents.birthday);
        formData.append('phone', dataStudents.phone);
        formData.append('direction', dataStudents.direction);
        formData.append('disease', dataStudents.disease);
        formData.append('level', dataStudents.level);
        formData.append('grade', dataStudents.aula);

        formData.append('fullnameAttorney', dataStudents.fullnameAttorney);
        formData.append('firtsnameAttorney', dataStudents.lastnameAttorney);
        formData.append('secondnameAttorney', dataStudents.secondanameAttorney);
        formData.append('dniAttorney', dataStudents.dniAttorney);
        formData.append('phoneAttorney', dataStudents.phoneAttorney);
        formData.append('parentAttorney', dataStudents.parentAttorney);

        formData.append('totalpay', dataStudents.totalamount);
        formData.append('subtotalpay', dataStudents.subamount);

        for (let i = 0; i < voucherData.length; i++) {
            formData.append('files[]', voucherData[i]);
        }

        try {
            
            await fetch(`${API.api}/college/students/create.php`, {
                method: 'POST',
                body: formData
            })
            .then((response) => response.json())
            .then((data) => {
                if (data.ok) {
                    handleViewModal('', '');
                    handleViewMessageAlert('success', data.message);
                    window.open(data.welcome);
                } else {
                    handleViewMessageAlert('warning', data.message);
                }
            })
            .catch((error) => {
                handleViewMessageAlert('error', error.message);
            })
            .finally(() => {
                setIsLoading(false);
            })

        } catch (error) {
            handleViewMessageAlert('error', error.message);
        }

    }

    const handleCreateRool = async () => {

        setIsLoading(true);

        const formData = new FormData();
        formData.append('role', dataCollaborators.role);
        formData.append('fullname', dataCollaborators.fullname);
        formData.append('firtsname', dataCollaborators.lastname);
        formData.append('secondname', dataCollaborators.secondname);

        formData.append('position', dataCollaborators.position)
        formData.append('dni', dataCollaborators.dni)
        formData.append('civilstatus', dataCollaborators.civilstatus)
        formData.append('birthday', dataCollaborators.birthday)
        formData.append('phone', dataCollaborators.phone)
        formData.append('email', dataCollaborators.email)
        formData.append('disease', dataCollaborators.disease)
        formData.append('direction', dataCollaborators.direction)

        try {
            
            await fetch(`${API.api}/usuarios/create.php`, {
                method: 'POST',
                body: formData
            })
            .then((response) => response.json())
            .then((data) => {
                if (data.ok) {
                    handleViewModal('', '');
                    handleViewMessageAlert('success', data.message);
                    window.open(data.welcome);
                } else {
                    handleViewMessageAlert('warning', data.message);
                }
            })
            .catch((error) => {
                handleViewMessageAlert('error', error.message);
            })
            .finally(() => {
                setIsLoading(false);
            })

        } catch (error) {
            handleViewMessageAlert('error', error.message);
        }

    }

    const handleCapturedLevels = async () => {

        try {
            
            const cachedLevels = JSON.parse(localStorage.getItem("collegeLevels"));
            if (cachedLevels) {
                setLevels(cachedLevels);
            } else {
                const res = await fetch(`${API.api}/college/levels/`);
                const data = await res.json();
                if (data.ok) {
                    setLevels(data.content);
                    localStorage.setItem("collegeLevels", JSON.stringify(data.content));
                } else {
                    console.log(data.message);
                }
            }

        } catch (error) {
            console.log(error.message);
        }

    }

    const handleCapturedClassroom = async () => {

        try {
            
            const cachedClassroom = JSON.parse(localStorage.getItem('collegeClassroom'));

            if (cachedClassroom) {
                setClassroom(cachedClassroom);
            } else {

                const res = await fetch(`${API.api}/college/classroom/`)
                const data = await res.json();
                if (data.ok) {
                    setClassroom(data.content);
                    localStorage.setItem("collegeClassroom", JSON.stringify(data.content));
                } else {
                    console.log(data.message);
                }

            }

        } catch (error) {
            console.log(error.message);
        }

    }

    useEffect(() => {
        if (levels.length === 0 && classroom.length === 0) {
            handleCapturedLevels();
            handleCapturedClassroom(); //
        }
    }, [levels, classroom]);

    const contextValue = {
        isLoading,
        levels, handleCapturedLevels,
        classroom, handleCapturedClassroom,
        dataStudents,
        setVoucherData,
        dataCollaborators,
        handleChageEntryData,handleChageEntryDataCollaborators,
        handleEnroll,
        handleCreateRool
    }

    return (
        <CollegeContext.Provider value={contextValue}>{children}</CollegeContext.Provider>
    )

}

export default CollegeContext;