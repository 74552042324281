import React, { useContext, useState } from 'react'
import { Link } from 'react-router-dom';
import { IconEye, IconEyeOff } from '@tabler/icons-react';

import AuthenticatedContex from '../context/AuthenticatedContex';

import './styles/login.css'
import UIContext from '../context/UIContext';
import Snackbar from '../components/Snackbar/Snackbar';

function LoginScreen() {

    const { handleLoginSessionUser, isLoadingAuth } = useContext(AuthenticatedContex);
    const { isViewMessageAlert } = useContext(UIContext);

    const [ tooglePassword, setTooglePassword] = useState(false);
    const [ entryUserData, setEntryUserData ] = useState({
        uuid: '',
        uupw: ''
    });

    return (

        <>
        
            <div className={`--l0g1n`}>

                <form className={`--form`} method='POST' onSubmit={(e) => handleLoginSessionUser(e, entryUserData.uuid, entryUserData.uupw)}>

                    <div className={`--form-group`}>
                        <div className={`--form-txt`}>
                            <h2>Inciar Sesión</h2>
                            <p>Ingresa con tus credenciales para poder acceder a más herramientas</p>
                        </div>
                    </div>

                    <div className={`--form-group`}>
                        <label className={`--label`} htmlFor='uuid'>Ingresar ID</label>
                        <div className={`--form-control`}>
                            <input type='text' className={`--entry --entry-uuid`} name='uuid' id='uuid' placeholder='Ingresar ID' aria-label='Ingresar ID' autoComplete='off' value={entryUserData.uuid} onChange={(e) => setEntryUserData(prevData => ({ ...prevData, uuid: e.target.value }))} />
                        </div>
                    </div>
                    
                    <div className={`--form-group`}>
                        <label className={`--label`} htmlFor='uupw'>Ingresar Contraseña</label>
                        <div className={`--form-control`}>
                            <input type={`${tooglePassword ? 'text' : 'password'}`} className={`--entry --entry-uupw`} name='uupw' id='uupw' placeholder='Ingresar Contraseña' aria-label='Ingresar Contraseña' autoComplete='off' value={entryUserData.uupw} onChange={(e) => setEntryUserData(prevData => ({ ...prevData, uupw: e.target.value }))}/>
                            <span className={`--span --span-ico`} onClick={() => setTooglePassword(!tooglePassword)}>
                                {tooglePassword ? <IconEye/> : <IconEyeOff/>}
                            </span>
                        </div>
                        <span><Link to={'/recover'}>¿Olvidaste tu contraseña?</Link></span>
                    </div>

                    <div className={`--form-group`}>
                        <button className={`--btn --btn-primary`}>{isLoadingAuth ? 'Cargando...' : 'Ingresar'}</button>
                    </div>

                </form>

            </div>

            {isViewMessageAlert.text !== '' && ( <Snackbar data={isViewMessageAlert} /> )}
        
        </>

    )
    
}

export default LoginScreen