import React, { createContext, useState, useContext } from "react";
import { jwtDecode } from "jwt-decode";
import API from "../api/api";
import Cookies from "js-cookie";
import UIContext from "./UIContext";

const RecoverAccountContext = createContext();

export const RecoverAccountProvider = ({ children }) => {

    const { handleViewMessageAlert, handleViewMained } = useContext(UIContext);

    const [ isToStep, setIsToStep ] = useState(1);

    const [ txtState, setTxtState ] = useState({
        title: 'Recuperar Contraseña',
        texto: 'No es divertido olvidar la cuenta 😔'
    });

    const [ userDataRecover, setUserRecover ] = useState({
        ruuid: '',
        cuurv: '',
        cuupw: ''
    });

    const [ isLoadingRecover, setIsLoadingRecover ] = useState(false);

    const handleCapturedData = (e) => {
        setUserRecover(prevData => ({
            ...prevData,
            [e.target.name]: e.target.value
        }));
    }    

    const handleCapturedIdUser = async () => {

        if (userDataRecover.ruuid === '') {
            handleViewMessageAlert('warning', 'Por favor ingresar con ID');
            return;
        }

        setIsLoadingRecover(true);

        const formData = new FormData();
        formData.append('uuid', userDataRecover.ruuid);

        function truncateNumber(number) {
            const truncatedNumber = '*'.repeat(6) + number.slice(-3);
            return truncatedNumber;
        }

        try {
            
            await fetch(`${API.api}/usuarios/recover/recover.php`, {
                method: 'POST',
                body: formData
            })
            .then((response) => response.json())
            .then((data) => {
                if (data.ok) {
                    setIsToStep(isToStep + 1);
                    let phone_user = jwtDecode(data.content);
                    let truncate = truncateNumber(phone_user.phone_token)
                    setTxtState(prevData => ({
                        ...prevData,
                        texto: `Hemos enviado el código de verificación al número <b>${truncate}</b>`
                    }));                    
                } else {
                    handleViewMessageAlert('warning', data.message);
                }
            })
            .catch((error) => {
                handleViewMessageAlert('error', error.message);
            })
            .finally(() => {
                setIsLoadingRecover(false);
            })

        } catch (error) {
            handleViewMessageAlert('error', error.message);
        }
    }

    const handleRecoverSessionUser = async () => {

        if (userDataRecover.cuurv === '' && userDataRecover.ruuid === '') {
            handleViewMessageAlert('warning', 'Por favor ingresa el código de 6 dígitos');
            return;
        }

        setIsLoadingRecover(true);

        const formData = new FormData();
        formData.append('code', userDataRecover.cuurv);
        formData.append('uuid', userDataRecover.ruuid);

        try {
            
            await fetch(`${API.api}/usuarios/recover/verify_password.php/?true`, {
                method: 'POST',
                body: formData
            })
            .then((response) => response.json())
            .then((data) => {
                if (data.ok) {
                    setIsToStep(isToStep+1);
                    setTxtState(prevData => ({
                        ...prevData,
                        texto: `Ahora puedes cambiar tu contraseña`
                    })); 
                } else {
                    handleViewMessageAlert('warning', data.message);
                }
            })
            .catch((error) => {
                handleViewMessageAlert('error', error.message);
            })
            .finally(() => {
                setIsLoadingRecover(false);
            })

        } catch (error) {
            handleViewMessageAlert('error', error.message);
        }

    }

    const handleChangeRecoverUser = async () => {

        if (userDataRecover.ruuid === '' && userDataRecover.cuupw === '') {
            handleViewMessageAlert('warning', 'Por favor completa los campos vacíos');
            return;
        } else if (userDataRecover.cuupw.length < 6) {
            handleViewMessageAlert('warning', 'Por favor la contraseña debe sera mayor a 6 dígitos');
            return;
        }

        setIsLoadingRecover(true);

        const formData = new FormData();
        formData.append('uuid', userDataRecover.ruuid)
        formData.append('uupw', userDataRecover.cuupw);

        try {
            
            await fetch(`${API.api}/usuarios/recover/change_password.php`, {
                method: 'POST',
                body: formData
            })
            .then((response) => response.json())
            .then((data) => {
                if (data.ok) {
                    
                    handleViewMessageAlert('success', data.message);
                    handleViewMained('home');
                    setIsToStep(isToStep+1);
                    setTxtState({
                        title: 'Contraseña actualizada',
                        texto: `Felicitaciones de nuevo tienes acceso a tu cuenta.`
                    }); 
                    Cookies.set('c_user', data.token, { expires: 365 });

                } else {
                    handleViewMessageAlert('warning', data.message);
                }
            })
            .catch((error) => {
                handleViewMessageAlert('error', error.message);
            })
            .finally(() => {
                setIsLoadingRecover(false);
            })

        } catch (error) {
            handleViewMessageAlert('error', error.message);
        }

    }

    const contextValue = {
        isToStep,
        txtState,
        isLoadingRecover,
        handleCapturedData,
        handleCapturedIdUser,
        handleRecoverSessionUser,
        handleChangeRecoverUser
    }

    return (
        <RecoverAccountContext.Provider value={contextValue}>{children}</RecoverAccountContext.Provider>
    )

}

export default RecoverAccountContext;