import React, { useContext } from 'react'
import { IconPlus, IconUserStar, IconSchool, IconApps } from '@tabler/icons-react'

import UIContext from '../../context/UIContext'

import './styles.css'

function ShortCut({ type }) {

    const { handleViewModal } = useContext(UIContext);

    return (

        <>
        
            {type === 'statistics' && (

                <div className={`--content-shortcut`}>

                    <div className={`--title-shortcut`}>
                        <h4>Estadisticas</h4>
                    </div>

                    <div className={`--box-shortcut`}>

                        <div className={`--target-shortcut`}>

                            <div className={`--txt-shortcut`}>150</div>
                            <div className={`--ico-shortcut`}><IconUserStar/></div>

                        </div>
                        <div className={`--target-shortcut`}>

                            <div className={`--txt-shortcut`}>150</div>
                            <div className={`--ico-shortcut`}><IconSchool/></div>

                        </div>
                        <div className={`--target-shortcut`}>
                        
                            <div className={`--txt-shortcut`}>150</div>
                            <div className={`--ico-shortcut`}><IconApps/></div>

                        </div>

                    </div>

                </div>

            )}

            {type === 'functions' && (

                <div className={`--content-shortcut`}>
                    <div className={`--title-shortcut`}>
                        <h4>Funciones</h4>
                    </div>
                    <div className={`--box-shortcut`}>
                        <button className={`--btn`} onClick={() => handleViewModal('Matricular', 'addStudent')}>
                            <span className={`--ico`}><IconPlus/></span>
                            <span className={`--txt`}>Matricular</span>
                        </button>
                        <button className={`--btn`} onClick={() => handleViewModal('Agregar Colaborador', 'addCollaborators')}>
                            <span className={`--ico`}><IconPlus/></span>
                            <span className={`--txt`}>Colaborador</span>
                        </button>
                        <button className={`--btn`} onClick={() => handleViewModal('Editar Alamcén', 'editAlmacen')}>
                            <span className={`--ico`}><IconPlus/></span>
                            <span className={`--txt`}>Almacén</span>
                        </button>
                        <button className={`--btn`} onClick={() => handleViewModal('Crear Reunión', 'addMeeting')}>
                            <span className={`--ico`}><IconPlus/></span>
                            <span className={`--txt`}>Reunión</span>
                        </button>
                    </div>
                </div>

            )}

            {type === 'studentsAnalytics' && (

                <div className={`--content-shortcut`}>

                    <div className={`--title-shortcut`}>
                        <h4>Estadisticas</h4>
                    </div>

                    <div className={`--box-shortcut`}>

                        <div className={`--target-shortcut`}>

                            <div className={`--txt-shortcut`}>150</div>
                            <div className={`--ico-shortcut`}><IconUserStar/></div>

                        </div>
                        <div className={`--target-shortcut`}>

                            <div className={`--txt-shortcut`}>150</div>
                            <div className={`--ico-shortcut`}><IconSchool/></div>

                        </div>
                        <div className={`--target-shortcut`}>
                        
                            <div className={`--txt-shortcut`}>150</div>
                            <div className={`--ico-shortcut`}><IconApps/></div>

                        </div>

                    </div>

                </div>

            )}
        
        </>

    )

}

export default ShortCut