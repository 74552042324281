import React, { useContext, useEffect } from 'react'
import { isMobile } from "react-device-detect";

import AuthenticatedContex from './context/AuthenticatedContex';

import HomeScreen from './screens/HomeScreen'
import LoginScreen from './screens/LoginScreen';

function App() {

    const { isAuthenticated } = useContext(AuthenticatedContex);

    useEffect(() => {
        if (isMobile) {
            window.location.href = 'https://m.portal.sigma-jauja.edu.pe';
        }
    }, []);

    return (

        <>

            { isAuthenticated ? ( <HomeScreen/> ) : ( <LoginScreen/> )  }
        
        </>

    )

}

export default App