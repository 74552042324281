import React, { createContext, useEffect, useState } from "react";
import API from "../api/api";

const TeachersContext = createContext();

export const TeachersProvider = ({ children }) => {

    const [ isteachersList, setIsTeachersList ] = useState([]);

    const handleTeacherList = async () => {

        try {
            
            const res = await fetch(`${API.api}/college/teachers/`);
            const data = await res.json();

            if (data.ok) {
                setIsTeachersList(data.content);
            } else {
                console.log(data.message);
            }

        } catch (error) {
            console.log(error.message);
        }

    }

    useEffect(() => {
        if (isteachersList.length === 0) {
            handleTeacherList();
        }
    }, [isteachersList]);

    const contextValue = {
        isteachersList
    }

    return (
        <TeachersContext.Provider value={contextValue}>{children}</TeachersContext.Provider>
    )

}

export default TeachersContext;