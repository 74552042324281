import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import { UIProvider } from "./context/UIContext";
import { AuthenticatedProvider } from "./context/AuthenticatedContex";
import { RecoverAccountProvider } from "./context/RecoverAccountContext";
import { TeachersProvider } from "./context/TeachersContext";

import App from "./App";
import ErrorScreen from "./screens/ErrorScreen";
import RecoverScreen from "./screens/RecoverScreen";

import './static/css/globals.css'
import { CollegeProvider } from "./context/CollegeContext";
import { StudentsProvider } from "./context/StudentsContext";

const router = createBrowserRouter([
    {
        path: '/',
        element: <App/>,
        errorElement: <ErrorScreen/>
    },
    {
        path: '/recover',
        element: <RecoverScreen/>,
        errorElement: <ErrorScreen/>
    }
]);

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(

    <UIProvider>
        <AuthenticatedProvider>
            <RecoverAccountProvider>
                <CollegeProvider>
                    <StudentsProvider>
                        <TeachersProvider>
                            <RouterProvider router={router} />
                        </TeachersProvider>
                    </StudentsProvider>
                </CollegeProvider>
            </RecoverAccountProvider>
        </AuthenticatedProvider>
    </UIProvider>

);