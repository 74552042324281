import React, { useContext, useState } from 'react'
import { IconPhoto } from '@tabler/icons-react';

import CollegeContext from '../../../context/CollegeContext';

import './styles.css'

function Students() {

    const { levels, classroom, dataStudents, handleChageEntryData, setVoucherData } = useContext(CollegeContext);
    
    const [ isVoucherLoad, setIsVoucherLoad ] = useState({
        style: '',
        texto: 'Cargar Comprobante'
    });

    const selectedLevel = classroom.filter((room) => room.level_classroom === dataStudents.level);

    const handleFileChange = (e) => {

        const files = Array.from(e.target.files);
        if (files.length > 5) {
            alert('Solo se pueden seleccionar un máximo de 5 archivos');
            return;
        }

        setIsVoucherLoad({ style: '--label-pay--selected', texto: `Se cargó ${files.length} archivo(s) correctamente` });
        setVoucherData(files);

    }

    return (

        <>

            <div>

                <div className='--title'>
                    <h4>Datos del Estudiante</h4>
                </div>

                <div className={`--separator`}></div>

                <div className={`--form-group --form-flex`}>
                    <div className={`--form-control`}>
                        <label className={`--label`} htmlFor='lastname'>Primer Apellido</label>
                        <input type='text' className={`--entry`} name='lastname' id='lastname' placeholder='Ingresar Primer Apellido' aria-label='Ingresar Primer Apellido' onChange={(e) => handleChageEntryData(e)} />
                    </div>
                    <div className={`--form-control`}>
                        <label className={`--label`} htmlFor='secondname'>Segundo Apellido</label>
                        <input type='text' className={`--entry`} name='secondname' id='secondname' placeholder='Ingresar Segundo Apellido' aria-label='Ingresar Segundo Apellido' onChange={(e) => handleChageEntryData(e)} />
                    </div>
                </div>

                <div className={`--form-group`}>
                    <div className={`--form-control`}>
                        <label className={`--label`} htmlFor='fullname'>Nombres Completos</label>
                        <input type='text' className={`--entry`} name='fullname' id='fullname' placeholder='Nombres Completos' aria-label='Nombres Completos' onChange={(e) => handleChageEntryData(e)} />
                    </div>
                </div>

                <div className={`--form-group --form-flex`}>
                    <div className={`--form-control`}>
                        <label className={`--label`} htmlFor='dni'>DNI</label>
                        <input type='text' className={`--entry`} name='dni' id='dni' placeholder='DNI' aria-label='DNI' onChange={(e) => handleChageEntryData(e)} />
                    </div>
                    <div className={`--form-control`}>
                        <label className={`--label`} htmlFor='sex'>Sexo</label>
                        <select className={`--select`} name='sex' id='sex' defaultValue='' onChange={(e) => handleChageEntryData(e)}>
                            <option value='' hidden selected>Seleccionar Sexo</option>
                            <option value={'M'}>Masculino</option>
                            <option value={'F'}>Feminino</option>
                        </select>
                    </div>
                </div>

                <div className={`--form-group --form-flex`}>
                    <div className={`--form-control`}>
                        <label className={`--label`} htmlFor='birthday'>Cumpleaños</label>
                        <input type='date' className={`--entry`} name='birthday' id='birthday' placeholder='Cumpleaños' aria-label='Cumpleaños' onChange={(e) => handleChageEntryData(e)} />
                    </div>
                    <div className={`--form-control`}>
                        <label className={`--label`} htmlFor='phone'>Número de contacto</label>
                        <input type='text' className={`--entry`} name='phone' id='phone' placeholder='Número de contacto' aria-label='Número de contacto' onChange={(e) => handleChageEntryData(e)} />
                    </div>
                </div>

                <div className={`--form-group --form-flex`}>
                    <div className={`--form-control`}>
                        <label className={`--label`} htmlFor='level'>Nivel</label>
                        <select className={`--select`} name='level' id='level' defaultValue='' onChange={(e) => handleChageEntryData(e)}>
                            <option value='' hidden selected>Seleccionar nivel</option>
                            {levels.map((level, index) => (
                                <option key={index} value={level.code_level}>{level.name_level}</option>
                            ))}
                        </select>
                    </div>
                    <div className={`--form-control`}>
                        <label className={`--label`} htmlFor='aula'>Aula</label>
                        <select className={`--select`} name='aula' id='aula' defaultValue='' onChange={(e) => handleChageEntryData(e)}>
                            <option value='' hidden selected>Seleccionar nivel</option>
                            {selectedLevel.map((room, index) => (
                                <option key={index} value={room.code_aula}>{room.name_classroom}</option>
                            ))}
                        </select>
                    </div>
                </div>

                <div className={`--form-group`}>
                    <div className={`--form-control`}>
                        <label className={`--label`} htmlFor='direction'>Dirección</label>
                        <input type='text' className={`--entry`} name='direction' id='direction' placeholder='Ingresar Dirección' aria-label='Ingresar Dirección' onChange={(e) => handleChageEntryData(e)} />
                    </div>
                </div>

                <div className={`--form-group`}>
                    <div className={`--form-control`}>
                        <label className={`--label`} htmlFor='disease'>Enfermedades</label>
                        <input type='text' className={`--entry`} name='disease' id='disease' placeholder='Enfermedades' aria-label='Enfermedades' onChange={(e) => handleChageEntryData(e)} />
                    </div>
                </div>
            
            </div>

            <div className={`--separator`}></div>

            <div>

                <div className='--title'>
                    <h4>Datos del Apoderado</h4>
                </div>

                <div className={`--separator`}></div>

                <div className={`--form-group --form-flex`}>
                    <div className={`--form-control`}>
                        <label className={`--label`} htmlFor='lastnameAttorney'>Primer Apellido</label>
                        <input type='text' className={`--entry`} name='lastnameAttorney' id='lastnameAttorney' placeholder='Ingresar Primer Apellido' aria-label='Ingresar Primer Apellido' onChange={(e) => handleChageEntryData(e)} />
                    </div>
                    <div className={`--form-control`}>
                        <label className={`--label`} htmlFor='secondanameAttorney'>Segundo Apellido</label>
                        <input type='text' className={`--entry`} name='secondanameAttorney' id='secondanameAttorney' placeholder='Ingresar Segundo Apellido' aria-label='Ingresar Segundo Apellido' onChange={(e) => handleChageEntryData(e)} />
                    </div>
                </div>

                <div className={`--form-group`}>
                    <div className={`--form-control`}>
                        <label className={`--label`} htmlFor='fullnameAttorney'>Nombres Completos</label>
                        <input type='text' className={`--entry`} name='fullnameAttorney' id='fullnameAttorney' placeholder='Nombres Completos' aria-label='Nombres Completos' onChange={(e) => handleChageEntryData(e)} />
                    </div>
                </div>

                <div className={`--form-group --form-flex`}>
                    <div className={`--form-control`}>
                        <label className={`--label`} htmlFor='dniAttorney'>DNI</label>
                        <input type='text' className={`--entry`} name='dniAttorney' id='dniAttorney' placeholder='DNI' aria-label='DNI' onChange={(e) => handleChageEntryData(e)} />
                    </div>
                    <div className={`--form-control`}>
                        <label className={`--label`} htmlFor='phoneAttorney'>Número de contacto</label>
                        <input type='text' className={`--entry`} name='phoneAttorney' id='phoneAttorney' placeholder='Número de contacto' aria-label='Número de contacto' onChange={(e) => handleChageEntryData(e)} />
                    </div>
                    <div className={`--form-control`}>
                        <label className={`--label`} htmlFor='parentAttorney'>Seleccionar Parentesco</label>
                        <select className={`--select`} name='parentAttorney' id='parentAttorney' defaultValue=''>
                            <option value='' hidden selected>Seleccionar Parentesco</option>
                            <option value='Padre'>Padre</option>
                            <option value='Madre'>Madre</option>
                            <option value='Apoderado'>Apoderado</option>
                        </select>
                    </div>
                </div>
            
            </div>

            <div className={`--separator`}></div>

            <div>

                <div className='--title'>
                    <h4>Datos del Pago</h4>
                </div>

                <div className={`--separator`}></div>

                <div className={`--form-group --form-flex`}>
                    <div className={`--form-control`}>
                        <label className={`--label`} htmlFor='totalamount'>Total del Pago</label>
                        <input type='text' className={`--entry`} name='totalamount' id='totalamount' placeholder='Total del Pago' aria-label='Total del Pago' onChange={(e) => handleChageEntryData(e)} />
                    </div>
                    <div className={`--form-control`}>
                        <label className={`--label`} htmlFor='subamount'>Subtotal del Pago</label>
                        <input type='text' className={`--entry`} name='subamount' id='subamount' placeholder='Subtotal del Pago' aria-label='Subtotal del Pago' onChange={(e) => handleChageEntryData(e)} />
                    </div>
                </div>

                <div className={`--form-group`}>
                    <div className={`--form-control`}>
                        <label className={`--label-pay ${isVoucherLoad.style}`} htmlFor='labelPay'>
                            <div className='--flex-txt'>
                                <IconPhoto/>
                                <span>{isVoucherLoad.texto}</span>
                            </div>
                        </label>
                        <input type='file' className={`--entry-pay`} name='labelPay' id='labelPay' placeholder='Pago' aria-label='Pago' onChange={handleFileChange} accept='image/*, .pdf' multiple/>
                    </div>
                </div>
            
            </div>

        </>

    )

}

export default Students